import React, { type FunctionComponent } from 'react';

interface Props {
  className?: string;
  clipPathId?: string;
}

const BarGraphIcon: FunctionComponent<Props> = ({ className, clipPathId }) => {
  let uniqueId = clipPathId;

  if (!uniqueId) {
    const randomId = Math.random().toString(36).substring(2, 15);
    uniqueId = `clipBarGraphIcon${randomId}`;
  }

  return (
    <svg
      className={className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="observation-bar-graph-icon"
    >
      <g clipPath={`url(#${uniqueId})`}>
        <path
          d="M2.5 9.73755C2.5 9.18526 2.94772 8.73755 3.5 8.73755C4.05228 8.73755 4.5 9.18526 4.5 9.73755V12.7375H2.5V9.73755Z"
          fill="#98A2AF"
        />
        <path
          d="M5.5 7.73755C5.5 7.18526 5.94772 6.73755 6.5 6.73755C7.05228 6.73755 7.5 7.18526 7.5 7.73755V12.7375H5.5V7.73755Z"
          fill="#98A2AF"
        />
        <path
          d="M8.5 5.73755C8.5 5.18526 8.94772 4.73755 9.5 4.73755C10.0523 4.73755 10.5 5.18526 10.5 5.73755V12.7375H8.5V5.73755Z"
          fill="#98A2AF"
        />
        <path
          d="M11.5 8.73755C11.5 8.18526 11.9477 7.73755 12.5 7.73755C13.0523 7.73755 13.5 8.18526 13.5 8.73755V12.7375H11.5V8.73755Z"
          fill="#98A2AF"
        />
      </g>
      <defs>
        <clipPath id={uniqueId}>
          <rect width="16" height="16" fill="white" transform="translate(0 0.737549)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BarGraphIcon;
