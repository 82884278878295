import React, { type FunctionComponent } from 'react';

const WindStationIcon: FunctionComponent = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="observation-wind-station-icon"
  >
    <path
      d="M7.85221 8.81725C7.26709 8.81725 6.70594 8.58245 6.29219 8.16451C5.87845 7.74658 5.64602 7.17973 5.64602 6.58868C5.64602 5.99762 5.87845 5.43078 6.29219 5.01284C6.70594 4.5949 7.26709 4.36011 7.85221 4.36011V8.81725Z"
      fill="#98A2AF"
    />
    <path
      d="M8.88318 10.303C9.17574 9.79109 9.65761 9.41759 10.2228 9.26461C10.788 9.11164 11.3902 9.19172 11.8969 9.48725C12.4036 9.78277 12.7734 10.2695 12.9248 10.8404C13.0763 11.4114 12.997 12.0197 12.7044 12.5315L8.88318 10.303Z"
      fill="#98A2AF"
    />
    <path
      d="M6.82124 10.303C7.1138 10.8148 7.19308 11.4231 7.04164 11.994C6.8902 12.565 6.52044 13.0517 6.01372 13.3472C5.50699 13.6428 4.9048 13.7229 4.33962 13.5699C3.77443 13.4169 3.29256 13.0434 3 12.5315L6.82124 10.303Z"
      fill="#98A2AF"
    />
    <path
      d="M9.32301 9.93153C9.32301 10.7521 8.66451 11.4172 7.85221 11.4172C7.03991 11.4172 6.38141 10.7521 6.38141 9.93153C6.38141 9.111 7.03991 8.44582 7.85221 8.44582C8.66451 8.44582 9.32301 9.111 9.32301 9.93153Z"
      fill="#98A2AF"
    />
  </svg>
);

export default WindStationIcon;
