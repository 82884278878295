import React from 'react';
import classNames from 'classnames';
import { Chip } from '@mui/material';

import styles from './ObservedBadge.module.scss';

interface ObservedBadgeProps {
  className?: string;
  size?: 'small' | 'medium';
}

const ObservedBadge: React.FC<ObservedBadgeProps> = ({ className, size = 'medium' }) => (
  <Chip
    className={classNames(styles.observedBadge, styles[size], className)}
    data-testid="observed-badge"
    label="Observed"
    variant="outlined"
  />
);

export default ObservedBadge;
