import React, { type FunctionComponent } from 'react';
import classNames from 'classnames';
import { Box, Typography } from '@mui/material';
import conditionClassModifier from 'utils/conditionClassModifier';
import { conditionEnumToText } from '@surfline/web-common';

import styles from './CurrentWaveRating.module.scss';

interface Props {
  direction?: 'HORIZONTAL' | 'VERTICAL';
  rating: string;
  size?: 'small' | 'medium';
}

export type CurrentWaveRatingProps = Props;

const CurrentWaveRating: FunctionComponent<Props> = ({
  direction = 'HORIZONTAL',
  rating,
  size = 'small',
}) => {
  const ratingMap = {
    VERY_POOR: 1,
    POOR: 2,
    POOR_TO_FAIR: 3,
    FAIR: 4,
    FAIR_TO_GOOD: 5,
    GOOD: 5,
    EPIC: 5,
  } as const;

  const filledBars = ratingMap[rating as keyof typeof ratingMap] || 0;
  const shouldAnimate = rating === 'GOOD' || rating === 'EPIC';

  return (
    <Box
      className={classNames(styles.container, styles[size], { [styles.animate]: shouldAnimate })}
      data-testid="current-wave-rating-container"
    >
      {direction === 'HORIZONTAL' && (
        <Typography
          variant="callout1"
          component="span"
          className={classNames(
            styles.rating,
            `sl-conditions-color--${conditionClassModifier(rating)}-color`,
          )}
          data-testid="current-wave-rating-rating"
        >
          {conditionEnumToText(rating, ' ')}
        </Typography>
      )}
      <Box
        className={classNames({
          [styles.bars]: true,
          [styles.verticalBars]: direction === 'VERTICAL',
        })}
        data-testid="current-wave-rating-bars"
      >
        {Array.from({ length: 5 }, (_, i) => (
          <Box
            key={`rating-bar-${i + 1}`}
            className={classNames(styles.bar, {
              [`sl-conditions-color--${conditionClassModifier(rating)}-background`]: i < filledBars,
              [styles.verticalBar]: direction === 'VERTICAL',
            })}
            data-testid="current-wave-rating-bar"
          />
        ))}
      </Box>
    </Box>
  );
};

export default CurrentWaveRating;
