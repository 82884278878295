import React, { type FunctionComponent } from 'react';

const CameraInsightsObservationIcon: FunctionComponent = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="observation-smart-cam-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 6.28195V11.5711L11.0556 10.1488V11.7473C11.0556 12.1301 10.7797 12.4404 10.4394 12.4404H3.11614C2.77581 12.4404 2.5 12.1301 2.5 11.7473L10.4394 5.56543C10.7797 5.56543 11.0556 5.87571 11.0556 6.25857V7.70431L13.5 6.28195ZM2.5 5.88965C2.5 5.71167 2.64428 5.56738 2.82227 5.56738H8.36523C8.54322 5.56738 8.6875 5.71167 8.6875 5.88965C8.6875 6.06763 8.54322 6.21191 8.36523 6.21191H2.82227C2.64428 6.21191 2.5 6.06763 2.5 5.88965ZM2.82227 6.85645C2.64428 6.85645 2.5 7.00073 2.5 7.17871C2.5 7.35669 2.64428 7.50098 2.82227 7.50098H6.5319C6.70988 7.50098 6.85417 7.35669 6.85417 7.17871C6.85417 7.00073 6.70988 6.85645 6.5319 6.85645H2.82227ZM2.5 8.46777C2.5 8.28979 2.64428 8.14551 2.82227 8.14551H5.0194C5.19738 8.14551 5.34167 8.28979 5.34167 8.46777C5.34167 8.64576 5.19738 8.79004 5.0194 8.79004H2.82227C2.64428 8.79004 2.5 8.64576 2.5 8.46777ZM2.5 9.75684C2.5 9.57885 2.64428 9.43457 2.82227 9.43457H3.55273C3.73072 9.43457 3.875 9.57885 3.875 9.75684C3.875 9.93482 3.73072 10.0791 3.55273 10.0791H2.82227C2.64428 10.0791 2.5 9.93482 2.5 9.75684Z"
      fill="#98A2AF"
    />
  </svg>
);

export default CameraInsightsObservationIcon;
